import {Button, ContextMenu, Icon, Li} from '@startlibs/components'
import {Link} from '@reach/router'
import {TextInput} from '@startlibs/form'
import {formatDate, getColor, media, wrapLazy} from '@startlibs/utils'
import {usePopupToggle, useToggle} from '@startlibs/core'
import React, {useMemo, useState} from 'react'
import _ from 'lodash/fp'
import styled, {createGlobalStyle, css} from 'styled-components'

import {HelpButton} from 'sharecomponents/src/components/HelpButton'
import {useAuthSuspense} from 'sharecomponents/src/hooks/useSuspense'

import {ACCESSION_NUMBER, STUDY_UID} from '../enums/RecordType'
import {AcceptTermsDialog} from './recordList/AcceptTermsDialog'
import {CreditCardCheckout} from "./checkout/CreditCardCheckout";
import { InfoBox } from '../components/InfoBox';
import { PurviewFooter } from '../components/PurviewFooter';
import {RecordsTable} from './recordList/RecordsTable'
import {TextButton} from '../components/StyledComponents'
import {dicomDateToDate} from '../utils'
import {jwtGetFetcher} from '../utils/authFetch'

const StudyListPage = styled.div`
  padding: 8rem 1.5rem 6rem;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
  max-width: 80rem;
  min-height: 100vh;
  ${media.max(799)`
    max-width: 45rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 12rem;
  `}
  ${media.max(520)`
    padding-top: 6.25rem;
    padding-bottom: 12rem;
  `}
`
export const Navbar = styled.div`
  background: white;
  height: 5.5rem;
  left:0;
  right:0;
  top: 0;
  position: fixed;
  z-index: 100;
  min-width: 320px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px, rgba(0, 0, 0, 0.2) 0px 0px 5px 1px;
  ${media.max(520)`
    height: 5rem;
  `}
`
export const NavbarSystemLogo = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: ${getColor('gray120')};
  img {
    max-width: 180px;
    max-height: 3rem;
    display: inline-block;
    margin-right: 1rem;
  }
  ${media.max(520)`
    font-size: 14px;
    img {
      max-width: 120px;
      max-height: 3rem;
    }
  `}
  ${media.max(320)`
    span {
      display: none;
    }
  `}
`
const Header = styled.div`
  > div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .heading {
    border-bottom: 1px solid ${getColor('gray210')};
    padding-bottom: 0.75rem;
    color: rgba(0,0,0,.45);
    font-size: 13px;
  }
  ${Button} {
    white-space: nowrap;
    flex-shrink: 0;
  }
  .patient-name {
    font-weight: 600;
    color: ${getColor('gray90')};
    font-size: 16px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    ${Icon} {
      font-size: 18px;
      margin-right: 0.5rem;
    }
    .patient-id {
      font-size: 13px;
      font-weight: 400;
      color: ${getColor('gray120')};
      white-space: nowrap;
    }
  }
  .found-records {
    white-space: nowrap;
  }
  ${media.max(520)`
    .heading {
      display: block;
      border-bottom: none;
      margin-bottom: 0;
      padding-left: 4px;
    }
    .patient-name {
      font-size 15px;
      ${Icon} {
        font-size: 16px;
        margin-right: 4px;
      }
    }
    .found-records {
      margin-top: 0.25rem;
    }
  `}
`
const SearchInput = styled.div`
  width: 20rem;
  margin-right: 1rem;
  position: relative;
  ${Icon} {
    font-size: 20px;
    color: rgba(0,0,0,0.25);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    &.clear-icon {
      left: auto;
      right: 0.75rem;
      cursor: pointer;
      background: rgba(0,0,0,0.25);
      padding: .25rem;
      border-radius: 1rem;
      width: 1.75rem;
      height: 1.75rem;
      color: white;
      text-align: center;
      line-height: 1;
      font-size: 16px;
      &:hover {
        background: rgba(0,0,0,0.45);
      }
    }
  }
  input {
    padding-left: 2.5rem;
    ${props => props.hasFilter && css`
      padding-right: 2.75rem;
    `}
  }
  ${media.max(799)`
    flex-grow: 1;
  `}
`

const MobileActions = styled.div`
  display: none;
  background: white;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
  padding: 1rem;
  ${props => props.noText && css` padding: .25rem; `}
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  ${media.max(799)`
    display: block;
    .unlock-multiple-records-button {
      padding: 1rem;
    }
    .record-selection-help-text {
      margin-bottom: .5rem;
    }
    .action-buttons {
      display: flex;
      ${Button} {
        flex-grow: 1;
      }
    }
  `}
`

const ActionsFooter = styled.div`
  text-align: right;
  margin-top: 1rem;
  ${media.max(799)`
    display: none;
  `}
`

const SortButton = styled(Button)`
    ${Icon} {
      margin-right: 4px;
    }
    ${media.max(440)`
      padding: 0 0 0 10px;
      .desktop-only {
        display: none !important;
      }
    `}
`
const SORT_LABEL = {
  'paid': "Status",
  'studyDate': "Exam date",
  'referringPhysician': 'Referring physician',
  'fullDescription': 'Exam description',
  'accessionNumber': 'Exam number'
}

const EmptyListPlaceholder = styled.div`
  padding: 5rem 1rem;
  text-align: center;
  background-color: rgba(0,0,0,0.05);
  border-radius: 8px;
  color: rgba(0,0,0,0.6);
  h2 {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
`

const addFullDescription = study => _.set(
  'searchDescription', `${study.modality} - ${study.studyDescription} (${formatDate(dicomDateToDate(study.studyDate), 'yyyy-MM-dd')})`,
  _.set('fullDescription', <>{study.modality} - {study.studyDescription}
    <span className="nowrap">({formatDate(dicomDateToDate(study.studyDate), 'yyyy-MM-dd')})</span></>, study)
)

const addRefPhysAndFomattedDate = record => _.flow(
  _.set('searchDescription', ''),
  _.set('formattedDate', formatDate(new Date(Number(record.studyDate)), "MM/dd/yyyy")),
  _.set('referringPhysician', _.uniq(record.studies.map(_.get('referringPhysician'))).join(", "))
)(record)

const FixHelpButton = createGlobalStyle`
${media.max(799)`
    ${HelpButton} {
      ${props => props.hasManyUnpaid && css`
        bottom: 5.5rem;
      `}
      ${props => props.higher && css`
        bottom: 7.75rem;
      `}
    }
  `}
`

const recordsLazy = wrapLazy((jwt) => jwtGetFetcher(jwt)('/api/v2/records?page=0'))

export const RecordList = ({providerInfo, navigate}) => {
  const jwt = window.sessionStorage.getItem('jwt')
  const patientName = window.sessionStorage.getItem('patientName')
  const patientID = window.sessionStorage.getItem('patientID')
  const acceptedTermsKey = window.sessionStorage.getItem('acceptedTermsKey')

  // const acceptedTerms = useToggle(window.localStorage.getItem(acceptedTermsKey))
  const acceptedTerms = useToggle(window.localStorage.getItem(acceptedTermsKey))

  if (!jwt) {
    navigate('/')
    return null
  }

  const loadedRecords = recordsLazy.use(jwt).read()

  /* // TEST CODE
   if (process.env.NODE_ENV === 'development' && studies.length === 1) {
     const fakeStudies = [
       {
         "studyUID": "1.3.12.2.1107.5.4.3.123456789012345.19950922.121803.7",
         "studyDate": "19941013",
         "studyDescription": "SHOULDER W/O",
         "modality": "CT",
         "referringPhysician": "Felipe Felisberto",
         "paid": true,
         "hasReport": false
       },
       {
         "studyUID": "1.3.12.2.1107.5.4.3.123456789012345.19950922.121803.8",
         "studyDate": "19941013",
         "studyDescription": "ECHOCARDIOGRAM",
         "modality": "CT",
         "referringPhysician": "Giovanni Piazza",
         "paid": true,
         "hasReport": true
       },
       {
         "studyUID": "1.3.12.2.1107.5.4.3.123456789012345.19950922.121803.9",
         "studyDate": "19941013",
         "studyDescription": "RIGHT FOOT",
         "modality": "CT",
         "referringPhysician": "Felipe Silva",
         "paid": true,
         "hasReport": false
       },
     ]
     studies.push(...fakeStudies)
   }
 */
  const query = useToggle('')
  const sortingPopup = usePopupToggle()
  const sorting = useToggle('studyDate')

  const multipleCheckoutDialog = useToggle()
  const checkoutSelectedRecords = useToggle()

  const [records, setRecords] = useState(() =>
    providerInfo.recordType === STUDY_UID
      ? loadedRecords.map(addFullDescription)
      : providerInfo.recordType === ACCESSION_NUMBER
      ? loadedRecords.map(_.update('studies', _.map(addFullDescription)))
      : loadedRecords.map(addRefPhysAndFomattedDate)
  )

  const payRecords = (paidRecords) => setRecords(records => records.map(study => paidRecords.indexOf(study) >= 0 ? _.set('paid', true, study) : study))

  const filteredRecords = useMemo(() =>
      !query.isOpen
        ? records
        : records.filter(record => [record.accessionNumber, record.referringPhysician, record.searchDescription, record.formattedDate].filter(Boolean).join("\u2028").toUpperCase().indexOf(query.isOpen.toUpperCase()) >= 0)
    , [query.isOpen, records])

  const sortedRecords = useMemo(() => {
    return _.orderBy(
      [sorting.isOpen, 'studyDate', 'referringPhysician',
        providerInfo.recordType === STUDY_UID
          ? 'fullDescription'
          : providerInfo.recordType === ACCESSION_NUMBER
            ? 'accessionNumber'
            : 'formattedDate'
      ], [sorting.isOpen === 'studyDate' ? 'desc' : 'asc', 'desc', 'asc', 'asc'], filteredRecords)
  }, [sorting.isOpen, filteredRecords])

  const howManyUnpaid = useMemo(() => records.filter(_ => !_.paid).length, [records])
  const hasManyUnpaid = howManyUnpaid > 1 && providerInfo.recordAccessFee > 0
  const howManyUnpaidFiltered = useMemo(() => sortedRecords.filter(_ => !_.paid).length, [sortedRecords])

  React.useEffect(() => {
    if (providerInfo.shouldDisplayTerms && acceptedTerms.isOpen && !window.localStorage.getItem(acceptedTermsKey,"true")) {
      navigate("/")
    }
  },[acceptedTerms.isOpen])

  return <StudyListPage>
    <Navbar>
      <NavbarSystemLogo>
        <img src={providerInfo.logoURL} alt="Logo"/>
        <span>{providerInfo.headerText || "Patient Access"}</span>
      </NavbarSystemLogo>
      <TextButton as={Link} to="/">Logout</TextButton>
    </Navbar>
    <Header>
      <div className="heading">
        <div className="patient-name"><Icon icon="user"/>
          <div><span>{patientName}</span> <span className="patient-id">(ID: {patientID})</span></div>
        </div>
        <div className="found-records">{records.length} record{records.length > 1 ? 's' : ''} found</div>
      </div>
      <div>
        <SearchInput hasFilter={query.isOpen}><Icon icon="search"/><TextInput
          placeholder="Search records" raw setValue={query.openWith}
          value={query.isOpen}
        />{query.isOpen && <Icon icon="x" className="clear-icon" onClick={query.willOpenWith('')}/>}</SearchInput>
        <SortButton outline icon="sort" onClick={sortingPopup.toggle}>
          <span className="desktop-only">Sort by {SORT_LABEL[sorting.isOpen]}</span>
          {
            sortingPopup.isOpen &&
            <ContextMenu fixed>
              {Object.keys(SORT_LABEL)
                .filter(k => providerInfo.recordType === ACCESSION_NUMBER
                  ? k !== "fullDescription"
                  : k !== "accessionNumber"
                )
                .map(sortBy =>
                  (sortBy !== "paid" || howManyUnpaidFiltered > 0) &&
                  <Li
                    key={sortBy} icon={sorting.isOpen === sortBy ? 'check' : undefined}
                    onClick={sorting.willOpenWith(sortBy)} label={SORT_LABEL[sortBy]}
                  />
                )}
            </ContextMenu>
          }
        </SortButton>
      </div>
    </Header>
    {
      howManyUnpaid === records.length &&
      <InfoBox lightBlue withIcon>
        <Icon icon="info"/>
        <span>Click "Unlock" on an item below to enable viewing, downloading and sharing the exam(s).</span>
      </InfoBox>
    }
    {
      (howManyUnpaidFiltered === 0 && checkoutSelectedRecords.isOpen)
        ? <EmptyListPlaceholder>
          <h2>No locked medical records matched your search query.</h2>
          <p>Try searching for a different term or click bellow to view all locked records.</p>
          <div><Button small onClick={query.willOpenWith('')}>View all locked records</Button></div>
        </EmptyListPlaceholder>
        : sortedRecords.length === 0
        ? <EmptyListPlaceholder>
          <h2>No medical records matched your search query.</h2>
          <p>Try searching for a different term or click bellow to view all records.</p>
          <div><Button small onClick={query.willOpenWith('')}>View all records</Button></div>
        </EmptyListPlaceholder>
        : <RecordsTable
          acceptedTerms={acceptedTerms}
          providerInfo={providerInfo}
          jwt={jwt}
          payRecords={payRecords}
          records={sortedRecords}
          recordAccessFee={providerInfo.recordAccessFee}
          checkoutSelectedRecords={checkoutSelectedRecords}
          hasManyUnpaid={hasManyUnpaid}
        />
    }

    {
      checkoutSelectedRecords.isOpen &&
      <ActionsFooter>
        <Button onClick={checkoutSelectedRecords.close}>Cancel</Button>
        <Button
          highlight
          icon="unlock"
          onClick={multipleCheckoutDialog.open}
          disabled={!checkoutSelectedRecords.isOpen.length}
        >Unlock selected records</Button>
      </ActionsFooter>
    }

    {
      hasManyUnpaid && (
        checkoutSelectedRecords.isOpen
          ? <MobileActions>
            <div className="record-selection-help-text">Select the records to unlock and confirm bellow</div>
            <div className="action-buttons">
              <Button onClick={checkoutSelectedRecords.close}>Cancel</Button>
              <Button
                highlight
                icon="unlock"
                onClick={multipleCheckoutDialog.open}
                disabled={!checkoutSelectedRecords.isOpen.length}
              >Unlock selected records</Button>
            </div>
            <FixHelpButton higher hasManyUnpaid={hasManyUnpaid}/>
          </MobileActions>
          : <MobileActions noText>
            <TextButton
              className="unlock-multiple-records-button"
              onClick={checkoutSelectedRecords.willOpenWith([])}
            ><Icon icon="unlock"/>Unlock multiple records</TextButton>
            <FixHelpButton hasManyUnpaid={hasManyUnpaid}/>
          </MobileActions>
      )
    }

    {
      multipleCheckoutDialog.isOpen &&
      <CreditCardCheckout
        acceptedTerms={!!acceptedTerms.isOpen}
        stripeKey={providerInfo.stripeKey}
        providerInfo={providerInfo}
        jwt={jwt}
        payStudies={payRecords}
        selectMore={howManyUnpaid > 0 && multipleCheckoutDialog.close}
        cancelSelection={checkoutSelectedRecords.close}
        closeDialog={() => {
          multipleCheckoutDialog.close();
          checkoutSelectedRecords.close()
        }}
        studies={checkoutSelectedRecords.isOpen}
        recordAccessFee={providerInfo.recordAccessFee}
      />
    }
    {
      providerInfo.shouldDisplayTerms && !acceptedTerms.isOpen && <AcceptTermsDialog
        closeDialog={acceptedTerms.open}
        onSuccess={() => window.localStorage.setItem(acceptedTermsKey,"true")}
        jwt={jwt}
        providerInfo={providerInfo}
      />
    }
    <PurviewFooter absolute providerInfo={providerInfo} jwt={jwt} />
  </StudyListPage>

}
